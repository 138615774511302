import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import { AbstractModal } from '@component/Modal';
let PaymentErrorModal = class PaymentErrorModal extends AbstractModal {
    onTryAgainBtnClick() {
        if (typeof this.payload.onTryAgain === 'function') {
            this.payload.onTryAgain();
        }
    }
};
PaymentErrorModal = __decorate([
    Component({ name: 'PaymentErrorModal' })
], PaymentErrorModal);
export { PaymentErrorModal };
export default PaymentErrorModal;
